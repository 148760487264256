enum ACTION_TYPES {
    SET_TRACKS_DATA ='@ORTALIOPL/SET_TRACKS_DATA',
    PLAY_PAUSE_TRACK ='@ORTALIOPL/PLAY_PAUSE_TRACK',
    PLAY_PAUSE_TRACK_SUCCESS ='@ORTALIOPL/PLAY_PAUSE_TRACK_SUCCESS',
    PLAY_PAUSE_TRACK_FAILURE ='@ORTALIOPL/PLAY_PAUSE_TRACK_FAILURE',
    STOP_PLAYBACK ='@ORTALIOPL/STOP_PLAYBACK',
    SET_TRACK_PROGRESS ='@ORTALIOPL/SET_TRACK_PROGRESS',
    TRACK_SEEK_TO ='@ORTALIOPL/TRACK_SEEK_TO',
    TRACK_SEEK_TO_SUCCESS ='@ORTALIOPL/TRACK_SEEK_TO_SUCCESS',
    TOGGLE_VOLUME ='@ORTALIOPL/TOGGLE_VOLUME',
    SET_LOOP_MODE='@ORTALIOPL/SET_LOOP_MODE',
    TOGGLE_TIMER_MODE='@ORTALIOPL/TOGGLE_TIMER_MODE',
    DECIDE_WHAT_PLAY_NEXT='@ORTALIOPL/DECIDE_WHAT_PLAY_NEXT',
    PLAY_NEXT_TRACK='@ORTALIOPL/PLAY_NEXT_TRACK',
    PLAY_PREVIOUS_TRACK='@ORTALIOPL/PLAY_PREVIOUS_TRACK',
    SET_CURRENT_TRACK='@ORTALIOPL/SET_CURRENT_TRACK',
    SET_CURRENT_TRACK_ERROR='@ORTALIOPL/SET_CURRENT_TRACK_ERROR',
    TOGGLE_PLAYER_VISIBLE='@ORTALIOPL/TOGGLE_PLAYER_VISIBLE',
    TOGGLE_PLAY_PAUSE_TRACK='@ORTALIOPL/TOGGLE_PLAY_PAUSE_TRACK',
}
export default ACTION_TYPES;
